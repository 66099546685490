import React, { useContext } from "react";
import {  Routes, Route, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Nav2 from "./layouts/nav/index2";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Dashboard from "./components/Dashboard/Dashboard";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

import Ujian from "./components/Ujian/Ujian";
import Monitoring from "./components/Ujian/Monitoring";
import WorkingProcess from "./components/Ujian/WorkingProcess";
import UjianDetail from "./components/Ujian/UjianDetail";
import UjianProcess from "./components/Ujian/UjianProcess";
import Bursa from "./components/Tryout/Bursa";
import Cart from "./components/Tryout/Cart";
import TryoutList from "./components/Tryout/TryoutList";
import Petunjuk from "./components/Tryout/Petunjuk";
import Hasil from "./components/Tryout/Hasil";
import Pembahasan from "./components/Tryout/Pembahasan";
import ListUjian from "./components/Ujian/ListUjian";

import { withRouter, mapStateToProps } from "../utils";
import { connect } from "react-redux";

const Markup = (props) => {
  const routhPath = [
    //GURU
    {url: "ujian", component: <Ujian/>},
    {url: "ujian/monitoring", component: <Monitoring/>},
    {url: "ujian/proses-pengerjaan", component: <WorkingProcess/>},
    {url: "ujian/detail", component: <UjianDetail/>},
    {url: "ujian/process", component: <UjianProcess/>},
    // {url: "bursa", component: <Bursa/>},
    // {url: "cart", component: <Cart/>},
    // {url: "tryout-list", component: <TryoutList/>},
    // {url: "petunjuk", component: <Petunjuk/>},
    // {url: "hasil", component: <Hasil/>},
    // {url: "pembahasan", component: <Pembahasan/>},

    // SISWA
    {url: "ujian/list", component: <ListUjian/>},
  ] 
  
  return (
    <>
      <Routes>                    
          <Route path='/page-error-400' element={<Error400 />} />
          <Route path='/page-error-403' element={<Error403 />} />
          <Route path='/page-error-404' element={<Error404 />} />
          <Route path='/page-error-500' element={<Error500 />} />
          <Route path='/page-error-503' element={<Error503 />} />     
          <Route path='/page-lock-screen' element={<LockScreen />} />     
            <Route element={<Layout2 />}>
              <Route path='/' exact element={<Dashboard/>} />
              <Route path='/dashboard' exact element={<Dashboard/>} />              
            </Route>           
            <Route element={<Layout2 />}>							        
                { routhPath.map((data, i) => (
                  <Route key={i} exact path={`/${data.url}`} element={data.component} />
                ))}  
            </Route>                              
				</Routes>        
	    <ScrollToTop />
    </>
  );
};

function Layout1(){
  const {  sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);
  let windowsize = window.innerWidth;
  // console.log(windowsize, 'size')
  return(
    
      <div id="main-wrapper" className={` show  ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>                
          <div className={`wallet-open  ${windowsize > 1199 ? 'active' : ''}`}>
           <Nav2 />
            <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
              <div className="container-fluid"> 
                <Outlet />
              </div>  
            </div> 
            <Footer changeFooter="footer-outer"/> 
            {/*<WalletBar />           */}
          </div>
      </div>            
    
  )
}

function Layout2(){
  const sideMenu = useSelector(state => state.sideMenu);
  const {  sidebariconHover } = useContext(ThemeContext);
  return(    
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>           
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
          <div className="container-fluid">							        
            <Outlet />							        
          </div>                        
        </div>  
        <Footer  changeFooter="out-footer style-2"/>           
    </div>  
    
  )
}



function Layout5(){
  const sideMenu = useSelector(state => state.sideMenu);
  const {  sidebariconHover } = useContext(ThemeContext);
  return(
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>  	      
          <Nav />
          <div className="content-body message-body mh-auto">
            <div className="container-fluid mh-auto p-0">   
              <Outlet />
            </div>
          </div>
    </div>
  )
}
function Layout6(){
  const sideMenu = useSelector(state => state.sideMenu);
  const {  sidebariconHover } = useContext(ThemeContext);
  return(
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>  
        <Nav />
        <div className="content-body">
          <div className="container-fluid">       
            <Outlet />
          </div>
        </div>
        <Footer  changeFooter="out-footer style-1"/>
      </div>
  )
}

export default withRouter(connect(mapStateToProps)(Markup));