import { IExamMultipleChoice } from 'healthicons-react';
let search = window.location.search;
let params = new URLSearchParams(search);
let id = params.get('id');
export const MenuList = [
    {
        title: 'Beranda',
        iconStyle: <i className="material-symbols-outlined">home</i>,
        to: 'ujian?id='+id
    },
    {
        title: 'Ujian',	
        classsChange: 'mm-collapse',		
        iconStyle: <IExamMultipleChoice height={36} width={36} color='white' fill='#fff' />,
        content: [
            {
                title: 'Monitoring',
                to: 'ujian/monitoring?id='+id,
            },
            {
                title: 'Proses Pengerjaan',
                to: 'ujian/proses-pengerjaan?id='+id,
            },
            // {
            //     title: 'Detail Ujian',
            //     to: 'ujian/detail?id='+id
            // },
            // {
            //     title: 'Proses Ujian',
            //     to: 'ujian/process?id='+id
            // }
           
        ],
    },
    
]

export const MenuListSiswa = [
    {
        title: 'Daftar Ujian',
        iconStyle: <i className="material-symbols-outlined">article</i>,
        to: 'ujian/list'
    }
    
]