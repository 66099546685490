import React,{useState, useContext, useEffect} from 'react';
import  DatePicker  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";


const Dashboard = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

	return(
		<>
		</>
	)
}
export default Dashboard;