import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Card, Col, Row, Table } from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination";
import Swal from "sweetalert2";

const ListUjian = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    loadData();
  }, [currentPage]);
  const loadData = async () => {
    var res = await axiosInstance.get(
      `tryout/paket-soal?page=${currentPage}&per_page=${perPage}`
    );
    setData(res.data.data);
    setTotalData(res.data.total);
  };

  const handlePaging = (page) => {
    console.log("page:", page);
    setCurrentPage(page);
    // loadData();
  };

  const handleListUjian = (id) => {
    navigate("/ujian/detail/?id=" + id);
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Ujian</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="width80">
                      <strong>#</strong>
                    </th>
                    <th>
                      <strong>Nama Ujian</strong>
                    </th>
                    <th>
                      <strong>Durasi</strong>
                    </th>
                    <th>
                      <strong>Aksi</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.detail.judul}</td>
                      <td>{item.detail.durasi / 60} Menit</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleListUjian(item.id)}
                        >
                          Ujikan
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination
                totalData={totalData}
                dataPerPage={perPage}
                onChange={handlePaging}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ListUjian;
