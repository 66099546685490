import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Button, Card, Col, ListGroup, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
import { mapStateToProps, withRouter } from "../../../utils";
import { connect } from "react-redux";

const UjianDetail = (props) => {
  const [data, setData] = useState([]);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get("id");
  const navigate = useNavigate();
  const [ujianList, setUjianList] = useState([]);

  useEffect(() => {
    if (id == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "ID Ujian Tidak Ditemukan. Anda akan di arahkan ke halaman daftar ujian",
        willClose: () => {
          navigate("/ujian/list");
        },
      });
    } else {
      loadUjianList();
    }
  }, []);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("id-ID", {
      year: "numeric",
      month: "long", // Nama bulan
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Format 24-jam
    }).format(date);
  };

  const loadUjianList = async () => {
    try {
      const res = await axiosInstance.get(`tryout/ujian?paket_soal_id=${id}`);
      var dataUjian = res.data.data;
      setUjianList(dataUjian);
      if (dataUjian.status == "started") {
        navigate("/ujian/process?id=" + id);
      }
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error.response.data.message}`,
          willClose: () => {
            navigate("/ujian/list");
          },
        });
      }
    }
  };

  const handleCreateUjian = async () => {
    try {
      var params = {
        paket_soal_id: id,
      };
      await axiosInstance.post(`tryout/ujian`, params);
      loadUjianList();
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error.response.data.message}`,
          willClose: () => {
            navigate("/ujian/list");
          },
        });
      }
    }
  };

  const startUjian = async (ujianId, status) => {
    try {
      if (status === "start") {
        await axiosInstance.put(`tryout/ujian/start/${ujianId}`);
        navigate("/ujian/process?id=" + ujianId);
      }
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error.response.data.message}`,
          willClose: () => {
            navigate("/ujian/list");
          },
        });
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Ujian" motherMenu="Ujian" />
      <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
        <Row>
          <Col xl={12}>
            <Card name="default-tab" className="dz-card">
              <Card.Header className="d-flex justify-content-between flex-wrap">
                <Card.Title>Detail Ujian</Card.Title>
                <div className="card-toolbar">
                  <button
                    className="btn btn-outline btn-sm btn-outline-primary"
                    onClick={handleCreateUjian}
                  >
                    Buat Ujian
                  </button>
                </div>
              </Card.Header>
              <Card.Body>
                {ujianList.length > 0 ? (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Kode Ujian</th>
                          <th>Status</th>
                          <th>Ujikan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ujianList.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.code}</td>
                            <td>
                              {item.peserta_tryout.status == "finished" && (
                                <>
                                  <div className="badge badge-success">
                                    Selesai
                                  </div>
                                </>
                              )}
                              {item.peserta_tryout.status == "created" && (
                                <>
                                  <div className="badge badge-secondary">
                                    Belum dikerjakan
                                  </div>
                                </>
                              )}
                              {item.peserta_tryout.status == "started" && (
                                <>
                                  <div className="badge badge-secondary">
                                    Berlangsung
                                  </div>
                                </>
                              )}
                            </td>
                            <td className="text-start">
                              {item.peserta_tryout.status == "created" ||
                              item.peserta_tryout.status == "started" ? (
                                <div
                                  className="btn btn-sm btn-outline-danger"
                                  onClick={() =>
                                    startUjian(
                                      item.id,
                                      item.peserta_tryout.status == "created"
                                        ? "start"
                                        : "finish"
                                    )
                                  }
                                >
                                  {item.peserta_tryout.status == "created" ? (
                                    <i className="fas fa-play"></i>
                                  ) : (
                                    <i className="fas fa-square"></i>
                                  )}
                                </div>
                              ) : (
                                <div className="btn btn-sm btn-outline-danger">
                                  <i className="fas fa-xmark"></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <div className="text-center">
                    <p>Belum ada ujian</p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default withRouter(connect(mapStateToProps)(UjianDetail));
